import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

import { ScrollingListService, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { CompanyManagementService } from '../../services/company-management.service';
import type { CompanyTreeItem } from '../../services/company';
import { CompanyAllocationService } from '../../services/company-allocation.service';
import { NgForm } from '@angular/forms';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { CompanyService } from '../../services/company.service';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ActionsMenu } from 'src/app/components/common-actions/common-actions.component';
import { StateService } from '@uirouter/angular';
import { Subscription } from 'rxjs';

@Component({
    selector: 'company-licenses',
    templateUrl: './company-licenses.component.html',
    styleUrls: ['./company-licenses.component.scss'],
    standalone: false
})
export class CompanyLicensesComponent implements OnInit, AfterViewInit, OnDestroy {

  filterConfig = {
    placeholder: 'Search Companies'
  };

  search = {
    companyId: this.companyStateService.getSelectedCompanyId(),
    sortBy: 'name',
    reverse: false,
    name: 'Companies',
    onListUpdate: (newItems) => {
      this.companyManagementService.createCompanyTree(this.companyTree, newItems);
      this.companyManagementService.loadSubcompanyTree(this.search.companyId, newItems.map((item) => item.id).join(','), this.companyTree);
    }
  };

  companies = this.ScrollingListService(this.companyApiService.getCompanies.bind(this.companyApiService), this.search);
  selectedCompany = this.companyStateService.getCopyOfSelectedCompany();
  companyTree: CompanyTreeItem[] = [];
  boundSearch: Function;
  saving: boolean;
  errorMessage: string;
  @ViewChild('allocationForm') allocationForm: NgForm;
  private formSubscription: Subscription;
  actions: ActionsMenu;

  constructor(
    private companyManagementService: CompanyManagementService,
    public companyAllocationService: CompanyAllocationService,
    public currentPlanService: CurrentPlanService,
    private ScrollingListService: ScrollingListService,
    private companyApiService: CompanyApiService,
    private companyStateService: CompanyStateService,
    public location: Location,
    public companyService: CompanyService,
    private processErrorCode: ProcessErrorCode,
    private stateService: StateService
  ) { }

  ngOnInit(): void {
    this.boundSearch = this.doSearch.bind(this);
    this.companyAllocationService.init();
    this.actions = {
      saveAction: {
        id: 'saveButton',
        label: `Save${ this.companyAllocationService.getRequiredLicenses() && !this.companyAllocationService.isManagedByParent ? ' and Checkout' : '' }`,
        disabled: true,
        requireRole: 'ua sa'
      },
      cancelAction: {
        id: 'cancelButton',
        label: 'Cancel',
        action: () => this.stateService.go('apps.billing.home')
      },
      moreOptions: [
        {
          id: 'exportButton',
          label: 'Export',
          action: () => this.exportCompany()
        }
      ]
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.allocationForm) {
        this.formSubscription = this.allocationForm.statusChanges.subscribe(() => {
          if (this.actions && this.actions.saveAction) {
            this.actions.saveAction.disabled = !this.allocationForm.dirty || this.saving;
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  setCompany(companyId: string): void {
    this.companyTree = [];
    this.companies.loadingItems = true;
    this.companyStateService.switchCompany(companyId);
  }

  isSubShown(parent: CompanyTreeItem): boolean {
    while(parent) {
      if (!parent.showSubs) {
        return false;
      }
      parent = parent.parent;
    }
    return true;
  }

  isLast(companyIndex: number): boolean {
    for (let i = companyIndex + 1; i < this.companyTree.length; i++) {
      if (this.companyTree[i].level === 0) {
        return false;
      }
    }
    return true;
  }

  doSearch() {
    this.companyTree = [];
    this.companies.doSearch();
  }

  save(ngForm: NgForm) {
    this.errorMessage = '';
    this.saving = true;
    this.companyAllocationService.saveAllocations()
      .then(() => {
        ngForm.form.markAsPristine();
      })
      .catch((error) => {
        this.errorMessage = this.processErrorCode(error);
      })
      .finally(() => {
        this.actions.saveAction.disabled = true;
        this.saving = false;
      });
  }

  exportCompany() {
    this.companyService.exportCompany(this.selectedCompany)
      .catch((error) => {
        if (error) {
          this.errorMessage = this.processErrorCode(error);
        }
      });
  }

}
